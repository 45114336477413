import React from "react";
import { useEffect } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import ResetPassword from "./components/auth/ResetPassword";
import ForgotPassword from "./components/auth/ForgotPassword";
import Verify from "./shades/Verify";
import Login from "./components/auth/Login";
import LoginMagicLink from "./components/auth/LoginMagicLink";
import SignUp from "./components/auth/SignUp";
import AuthRedirect from "./components/auth/AuthRedirect";
import ActiveSubscription from "./components/auth/ActiveSubscription";
import MailVerify from "./components/mailVery";
import ResendEmail from "./components/auth/ResendEmail";
import Logout from "./components/auth/Logout";
import Extension from "./components/Extension";
// import Bucket from "./components/Bucket";
import BucketInner from "./shades/cards/BucketInner";
import SubBucketInner from "./shades/cards/SubBucketInner";
import StarredCard from "./shades/cards/StarredCard";
import CardWithNotes from "./shades/cards/CardWithNotes";
import AllCards from "./shades/cards/AllCards";
import RecentCards from "./shades/cards/RecentCards";
import RecentEditCards from "./shades/cards/RecentEditCards";
import CardDetail from "./shades/cards/CardDetail";
import Gridview from "./shades/GridcompactView";
import CardListView from "./components/CardList/CardListView";
import GridProject from "./components/Projects/ProjectsGrid/ProjectsGrid";
import ProjectPreview from "./components/Projects/ProjectsPreview/ProjectsPreview";
import ProjectList from "./components/Projects/ProjectsList/ProjectsList";
import Tags from "./components/Tags/Tags";
import KindleList from "./components/Kindle/KindleList/KindleList";
import KindleDetail from "./components/Kindle/KindleDetail/KindleDetail";
import Dashboard from "./components/Dashboard/Dashboard";
import WhatIShared from "./components/WhatIShared/WhatIShared";
import EditProject from "./components/Projects/EditProject/EditProject";
import Popup from "./shades/cards/popup";
import Bookmarks from "./components/Bookmarks/Bookmarks";
import BookmarksList from "./components/Bookmark List/bookmarklist";
import Files from "./components/Files/Files";
import SliderPopup from "./shades/cards/Sliderpopup";
import PublicShared from "./components/PublicShare/Shared/Shared";
import PublicBucket from "./components/PublicBucket/PublicBucket/PublicBucket";
import EditCard from "./shades/cards/EditCard";
import EmailCampaign from "./components/EmailCampaign/EmailCampaign";
import PopupFinal from "./shades/cards/popupfinal.js";
import CardEdit from "./shades/cards/CardEdit";
import Settings from "./components/Settings/Settings";
import MyProfile from "./components/MyProfile/MyProfile";
import CreatePassword from "./components/auth/CreatePass";
import ShareWithMe from "./components/SharedWithMe/sharewithme";
import PricingPlans from "./components/PricingPlans/Plans/PricingPlans.js";
import UpgradePlan from "./components/MyProfile/Plan Details/UpgradePlan/UpgradePlan.js";
import RenewPlan from "./components/MyProfile/Plan Details/RenewPlan/RenewPlan.js";
import VisaPlan from "./components/MyProfile/Plan Details/VisaPlan/VisaPlan.js";
import PlanCheckout from "./components/PricingPlans/Plans/PlanCheckout.js";
import AddPayment from "./components/MyProfile/Plan Details/AddPayment/AddPayment.js";
import CancelPlan from "./components/MyProfile/Plan Details/CancelPlan/CancelPlan.js";
import TeamMembersPlan from "./components/MyProfile/Plan Details/TeamMembers/PlanTeamMembers.js";
import BillingInfo from "./components/MyProfile/Plan Details/BillingInfo/BillingInfo.js";
import ProfileAddPayment from "./components/MyProfile/Plan Details/MyProfile/ProfileAddPayment.js";
import UpdateBilling from "./components/MyProfile/Plan Details/UpdateBilling/UpdateBilling.js";
import InvoiceSummary from "./components/MyProfile/Invoice/InvoiceSummary/InvoiceSummary.js";
import UserPricing from "./components/NewPricingPlan/UserPricing/UserPricing.js";
import PaymentScreen from "./components/NewPricingPlan/PaymentScreen/PaymentScreen.js";
import SuccessScreen from "./components/NewPricingPlan/SuccessScreen/SuccessScreen.js";
import MySubscription from "./components/NewPricingPlan/MySubscription/MySubscription.js";
// import UpdateBillingplan from "./components/NewPricingPlan/UpdateBilling/UpdateBilling.js";
import UpdateBillingplan from "./components/NewPricingPlan/UpgradePlan/UpgradePlan.js";
import ManageTeam from "./components/NewPricingPlan/ManageTeam/ManageTeam.js";
import ManageInvite from "./components/NewPricingPlan/ManageInvite/ManageInvite.js";
import VerifyTokenExpiry from "./components/auth/verifyTokenExpiry";
import Expiry from "./components/auth/Expiry";
import NotFound from "./components/auth/NotFound";
import AiScreen from "./components/AiScreen/aiscreen.js";

const root = ReactDOM.createRoot(document.getElementById("root"));
const pathname = window.location.pathname;

const router = createBrowserRouter([
  {
    path: "/app",
    element: <App />,
    children: [
      // {
      //   path: "/app/compact",
      //   element: <Bucket />,
      // },
      {
        path: "/app/buckets/:id",
        element: (
          <ActiveSubscription>
            <BucketInner />
          </ActiveSubscription>
        ),
      },
      {
        path: "/app/sub-buckets/:id",
        element: (
          <ActiveSubscription>
            <SubBucketInner />
          </ActiveSubscription>
        ),
      },
      {
        path: "/app/card",
        element: (
          <ActiveSubscription>
            <AllCards />
          </ActiveSubscription>
        ),
      },
      {
        path: "/app/public/bucket/:id",
        element: (
            <AllCards />
        ),
      },
      {
        path: "/app/card-detail/:id",
        element: <ActiveSubscription><CardDetail /></ActiveSubscription>,
      },
      {
        path: "/app/EditCard/:id",
        element: <EditCard />,
      },
      {
        path: "/app/CardEdit/:id",
        element: <CardEdit />,
      },
      {
        path: "/app/buckets",
        element: (
          <ActiveSubscription>
            <Gridview />
          </ActiveSubscription>
        ),
      },
      {
        path: "/app/cardlist/:id",
        element: <CardListView />,
      },
      {
        path: "/app/gridProject",
        element: <GridProject />,
      },
      {
        path: "/app/projectPreview",
        element: <ProjectPreview />,
      },
      {
        path: "/app/SharedWithMe",
        element: (
          <ActiveSubscription>
            <ShareWithMe />
          </ActiveSubscription>
        ),
      },
      {
        path: "/app/projectlist",
        element: <ProjectList />,
      },
      {
        path: "/app/tags",
        element: (
          <ActiveSubscription>
            <Tags />
          </ActiveSubscription>
        ),
      },
      {
        path: "/app/kindleList",
        element: (
          <ActiveSubscription>
            <KindleList />
          </ActiveSubscription>
        ),
      },
      {
        path: "/app/kindledetail",
        element: <KindleDetail />,
      },
      {
        path: "/app/dashboard",
        element: (
          <ActiveSubscription>
            <Dashboard />
          </ActiveSubscription>
        ),
      },
      {
        path: "/app/whatishared",
        element: (
          <ActiveSubscription>
            <WhatIShared />
          </ActiveSubscription>
        ),
      },
      {
        path: "/app/starred",
        element: (
          <ActiveSubscription>
            <StarredCard />
          </ActiveSubscription>
        ),
      },
      {
        path: "/app/cardwithnotes",
        element: (
          <ActiveSubscription>
            <CardWithNotes />
          </ActiveSubscription>
        ),
      },
      {
        path: "/app/recent-saved",
        element: (
          <ActiveSubscription>
            <RecentCards />
          </ActiveSubscription>
        ),
      },
      {
        path: "/app/recent-edited",
        element: (
          <ActiveSubscription>
            <RecentEditCards />
          </ActiveSubscription>
        ),
      },
      {
        path: "/app/editProject",
        element: <EditProject />,
      },
      {
        path: "/app/popup",
        element: <Popup />,
      },
      {
        path: "/app/bookmarks",
        element: (
          <ActiveSubscription>
            <Bookmarks />
          </ActiveSubscription>
        ),
      },
      {
        path: "/app/bookmarklist",
        element: <BookmarksList />,
      },
      {
        path: "/app/files",
        element: (
          <ActiveSubscription>
            <Files />
          </ActiveSubscription>
        ),
      },
      {
        path: "/app/sliderpopup",
        element: <SliderPopup />,
      },
      // {
      //   path: '/app/publicshared',
      //   element: <PublicShared />
      // },
      {
        path: "/app/emailCampaign",
        element: <EmailCampaign />,
      },
      {
        path: "/app/popupFinal",
        element: <PopupFinal />,
      },
      {
        path: "/app/settings",
        element: (
          <ActiveSubscription>
            <Settings />
          </ActiveSubscription>
        ),
      },
      {
        path: "/app/myProfile",
        element: (
          <ActiveSubscription>
            <MyProfile />
          </ActiveSubscription>
        ),
      },
      {
        path: "/app/pricingPlans",
        element: <PricingPlans />,
      },
      {
        path: "/app/upgradePlan",
        element: <UpgradePlan />,
      },
      {
        path: "/app/renewPlan",
        element: <RenewPlan />,
      },
      {
        path: "/app/visaPlan",
        element: <VisaPlan />,
      },
      {
        path: "/app/planCheckout",
        element: <PlanCheckout />,
      },
      {
        path: "/app/addPayment",
        element: <AddPayment />,
      },
      {
        path: "/app/cancelPlan",
        element: <CancelPlan />,
      },
      {
        path: "/app/teamMembersPlan",
        element: <TeamMembersPlan />,
      },
      {
        path: "/app/billingInfo",
        element: <BillingInfo />,
      },
      {
        path: "/app/profileAddpayment",
        element: <ActiveSubscription><ProfileAddPayment /></ActiveSubscription>,
      },
      {
        path: "/app/updateBilling",
        element: <UpdateBilling />,
      },
      {
        path: "/app/invoiceSummary/:id",
        element: <InvoiceSummary />,
      },
      {
        path: "/app/userPricing",
        element: <UserPricing />,
      },
      {
        path: "/app/paymentScreen",
        element: <PaymentScreen />,
      },
      {
        path: "/app/successScreen",
        element: (
          <ActiveSubscription>
            <SuccessScreen />
          </ActiveSubscription>
        ),
      },
      {
        path: "/app/mySubscription",
        element: <ActiveSubscription><MySubscription /></ActiveSubscription>,
      },
      {
        path: "/app/updateBillingplan",
        element: <ActiveSubscription><UpdateBillingplan /></ActiveSubscription>,
      },
      {
        path: "/app/manageInvite",
        element: <ActiveSubscription><ManageTeam /></ActiveSubscription>,
      },
      {
        path: "/app/manageTeam",
        element: <ActiveSubscription><ManageInvite /></ActiveSubscription>,
      },
      {
        path: "/app/aiscreen",
        element: <ActiveSubscription><AiScreen /></ActiveSubscription>,
      },
    ],
  },
  {
    path: "/logout",
    element: <Logout />,
  },
  {
    path: "/",
    element: (
      <AuthRedirect>
        <Login />
      </AuthRedirect>
    ),
  },
  {
    path: "/signup",
    element: <SignUp />,
  },
  {
    path: "/register",
    element: <SignUp />,
  },
  {
    path: "/invitationExpired",
    element: <VerifyTokenExpiry />,
  },
  {
    path: "/notFound",
    element: <NotFound />,
  },
  {
    path: "/expiry",
    element: <Expiry />,
  },
  {
    path: "/mailVerify/:id",
    element: <Verify />,
  },
  {
    path: "/loginmagic",
    element: <LoginMagicLink />,
  },
  {
    path: "/resetPassword/:id",
    element: <ResetPassword />,
  },
  {
    path: "/createPassword/:id",
    element: <CreatePassword />,
  },
  {
    path: "/verifyEmail/:id",
    element: <MailVerify />,
  },
  {
    path: "/forgotpassword",
    element: (
      <AuthRedirect>
        <ForgotPassword />
      </AuthRedirect>
    ),
  },
  {
    path: "/extension",
    element: <Extension />,
  },
  {
    path: "/resendEmail",
    element: (
      <AuthRedirect>
        <ResendEmail />
      </AuthRedirect>
    ),
  },
  {
    path: "/public/card/:id",
    element: <PublicShared />,
  },
  {
    path: "/public/bucket/:id",
    element: <PublicBucket />,
  },
]);
root.render(
  // <React.StrictMode>
  <RouterProvider router={router} />
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
