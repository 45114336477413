import {React,Fragment,useState} from "react";
import './KindleDetail.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import trashIcon from "../../../assets/icons/Delete - Line icon.svg";
import filledIcon from "../../../assets/icons/filled_icon.svg";
import sourceLink from "../../../assets/icons/external_link_icon.svg";
import copyIcon from "../../../assets/icons/copyIcon.svg";
import linkIcon from "../../../assets/icons/cardLink.svg";
import shareIcon from "../../../assets/icons/shareLink.svg";
import Calender from "../../../assets/icons/Kindle - Calendar.svg";
import Kindleimage from "../../../assets/images/Kindle - Image 1.png";
import {faArrowUp,faStar} from "@fortawesome/free-solid-svg-icons";
import Tick from "../../../assets/icons/Kindle - Tick.svg"
import amazonkindle from"../../../assets/images/Amazon kindle svg logo.svg"
function KindleDetail() {
return(
<div className="kindle-detail">
   <h5><span className="project-nav">Kindle</span> / The ART of Fashion Journal by Eila Mell</h5>
   <hr className="mt-[20px] mb-[30px]" />
   <div className="row w-3/3 flex "> 
      <div >
         <div className="kindle-card">
            <h6>The ART of Fashion Journal by Eila Mell</h6>
            <div className="flex">
               <div className="mt-auto">
            <span>Highlights</span>
            </div>
            <div className="ml-auto">
            <img src={amazonkindle} alt="Kindleimage"/>
            </div>
            </div>
            <img className="my-[5px] kindle-image" src={Kindleimage} alt="Kindleimage"/>
            <div className="flex w-full">
              <div className="kindle-box bg-green w-[50%]">
               <img className="kindle-tick" src={Tick} alt="Tick" />
              </div>
              <div className="kindle-box ml-[2px] bg-afc604 w-[50%]">
              <img className="kindle-tick" src={Tick} alt="Tick" />

              </div>

            </div>
         </div>
      </div>
      <div className="w-3/3 ml-[24px]">
         <div className="kindlecard">
            <div className="menu-kindle">
               <div className="flex actionsHolder">
                  <div>
                     <a href="#" className="flex items-center">
                        <FontAwesomeIcon className="transform rotate-45" icon={faArrowUp } />
                        <span className="actionicon-content pl-3">Move to</span>
                     </a>
                  </div>
                  <div>
                     <a href="#" className="flex items-center pl-[14px]">
                     <img className="w-6 trash-icon" src={trashIcon} alt="trashIcon"/>
                     <span className="actionicon-content pl-3">Delete</span>
                     </a>
                  </div>
                  <div>
                     <a href="#" className="flex items-center pl-[14px]">
                     <img className="w-6" src={filledIcon} alt="copy-to" />
                     <span className="actionicon-content pl-3">Copy to</span>
                     </a>
                  </div>
                  <div>
                     <a href="#" className="flex items-center pl-[14px]">
                     <img className="w-5" src={sourceLink} alt="source-link" />
                     <span className="actionicon-content pl-3">Open in Kindle</span>
                     </a>
                  </div>
               </div>
               <div className="sharedAction flex items-center">
                  <div className="bg-[#FFE6D6] flex items-center px-3 py-1 rounded-2xl  mr-[10px]">
                     <p className="publicytext-content">Publicly Shared</p>
                     <span className="bg-white rounded-[50%] p-1 ml-2">
                     <img src={copyIcon} alt="copy-icon" />
                     </span>
                  </div>
                  <div className="w-6 mx-2">
                     <img className="cardicon-imgcontent menu-icon" src={linkIcon} alt="link-icon" />
                  </div>
                  <div className="w-7 mx-2">
                     <img className="cardicon-imgcontent menu-icon" src={shareIcon} alt="shareIcon" />
                  </div>
               </div>
            </div>
            <div className="mt-[10px]">
               <p className="last-updated flex"><img className="mr-[11px]" src={Calender} alt="trashIcon"/>Added on Jan 5, 2022 @ 05:00 AM / Locations 77</p>
               <p className="kindle-content">Collect recurring payments and automate your subscription billing logic - from checkout to reconciliation. Adjust price structures, product bundles, discount management, and recovery processes to maximise revenue.</p>
            </div>
         </div>
         <div className="kindlecard">
            <div className="menu-kindle">
               <div className="flex actionsHolder">
                  <div>
                     <a href="#" className="flex items-center">
                        <FontAwesomeIcon className="transform rotate-45" icon={faArrowUp } />
                        <span className="actionicon-content pl-3">Move to</span>
                     </a>
                  </div>
                  <div>
                     <a href="#" className="flex items-center pl-[14px]">
                     <img className="w-6 trash-icon" src={trashIcon} alt="trashIcon"/>
                     <span className="actionicon-content pl-3">Delete</span>
                     </a>
                  </div>
                  <div>
                     <a href="#" className="flex items-center pl-[14px]">
                     <img className="w-6" src={filledIcon} alt="copy-to" />
                     <span className="actionicon-content pl-3">Copy to</span>
                     </a>
                  </div>
                  <div>
                     <a href="#" className="flex items-center pl-[14px]">
                     <img className="w-5" src={sourceLink} alt="source-link" />
                     <span className="actionicon-content pl-3">Open in Kindle</span>
                     </a>
                  </div>
               </div>
               <div className="sharedAction flex items-center">
                  <div className="bg-[#FFE6D6] dark:bg-[#FFE6D6] flex items-center px-3 py-1 rounded-2xl mr-[10px]">
                     <p className="publicytext-content">Publicly Shared</p>
                     <span className="bg-white rounded-[50%] p-1 ml-2">
                     <img src={copyIcon} alt="copy-icon" />
                     </span>
                  </div>
                  <div className="w-6 mx-2">
                     <img className="cardicon-imgcontent menu-icon" src={linkIcon} alt="link-icon" />
                  </div>
                  <div className="w-7 mx-2">
                     <img className="cardicon-imgcontent menu-icon" src={shareIcon} alt="shareIcon" />
                  </div>
               </div>
            </div>
            <div className="mt-[10px]">
               <p className="last-updated flex"><img className="mr-[11px]" src={Calender} alt="trashIcon"/>Added on Jan 5, 2022 @ 05:00 AM / Locations 77</p>
               <p className="kindle-content">Showcase your collections in the most attractive way! Make your own stunning fashion lookbook and publish it online or download it for print. </p>
               <p className="kindle-content">Collect recurring payments and automate your subscription billing logic - from checkout to reconciliation. Adjust price structures, product bundles, discount management, and recovery processes to maximise revenue.</p>

            </div>
         </div>
      </div>
   </div>
</div>
);
}
export default KindleDetail;