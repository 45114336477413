/*global chrome*/
// AuthRedirect.js
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from "axios";

const ActiveSubscription = ({ children }) => {
  const navigate = useNavigate();

  useEffect(() => {
    // Check the user's login status (you might want to use your own logic)
    // const isUserLoggedIn = /* your logic to check if the user is logged in */;

    let userData =JSON.parse(localStorage.getItem('userData'));
    const urlSearchParamss = new URLSearchParams(window.location.search);
    // console.log(userData.isActiveSubscription,"userData.isActiveSubscription");
    // const isUserLoggedIn = localStorage.getItem('userData') ? true : false;
    if(urlSearchParamss.get('payment_intent')){
        userData.isActiveSubscription = true;
        // console.log(JSON.stringify(userData));
        // console.log(localStorage.getItem('userData'));
        localStorage.setItem('userData',JSON.stringify(userData));
    }

    const fetchUserAuth = async () => {
      const token = localStorage.getItem('token');
      try{
        const response = await axios.get(
          `${process.env.REACT_APP_API}users/me`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        console.log(response, 'Me response for authentication');
        if(!response?.data?.data?.status){
          localStorage.removeItem('userData');
          localStorage.removeItem('token');
          localStorage.removeItem('settingsToken')
          localStorage.removeItem('cardFilter');
          localStorage.removeItem('bucketFilter');
          localStorage.removeItem('bucketView');
          localStorage.removeItem('sharecardFilter');
          localStorage.removeItem('subscriptionpriceId');
          localStorage.removeItem('subscriptionType');
          localStorage.removeItem('renewPlan');
          localStorage.removeItem('renewPrice');
          localStorage.removeItem('renewPriceId');
          localStorage.removeItem('planPrice');
          localStorage.removeItem('subscriptionplanId');
          localStorage.removeItem('sharedClickCardId');
          localStorage.removeItem('sharedCardId');
          localStorage.removeItem('cardFilter');
          localStorage.removeItem('browserId');
          sendTokenToChromeExtension({ extensionId: 'mnocfefponilgkmehcdbmdojkeodoffd', removejwt: true });
          navigate('/');
        }
        if(response?.data?.data){
          if (!response?.data?.data?.isActiveSubscription && !urlSearchParamss.get('payment_intent') && response?.data?.data?.status) {
            if(!response?.data?.data?.isComplementary){
              if(!response?.data?.data?.isTeamMember){
                navigate('/app/userPricing'); // Redirect to your main app page
              }
            }
          }
        }
      } catch(error){
        console.error(error);
        localStorage.removeItem('userData');
        localStorage.removeItem('token');
        localStorage.removeItem('settingsToken')
        localStorage.removeItem('cardFilter');
        localStorage.removeItem('bucketFilter');
        localStorage.removeItem('bucketView');
        localStorage.removeItem('sharecardFilter');
        localStorage.removeItem('subscriptionpriceId');
        localStorage.removeItem('subscriptionType');
        localStorage.removeItem('renewPlan');
        localStorage.removeItem('renewPrice');
        localStorage.removeItem('renewPriceId');
        localStorage.removeItem('planPrice');
        localStorage.removeItem('subscriptionplanId');
        localStorage.removeItem('sharedClickCardId');
        localStorage.removeItem('sharedCardId');
        localStorage.removeItem('cardFilter');
        localStorage.removeItem('browserId');
        sendTokenToChromeExtension({ extensionId: 'mnocfefponilgkmehcdbmdojkeodoffd', removejwt: true });
        navigate('/');
      }
    }

    const sendTokenToChromeExtension = ({ extensionId, removejwt }) => {
      try {
          if(chrome && chrome?.runtime){
              chrome.runtime.sendMessage(extensionId, { removejwt }, response => {
                  // if (!response.success) {
                  //   console.log('error sending message', response);
                  //   return response;
                  // }
                  // console.log('Sucesss ::: ', response.message)
              
              });
          }
      } catch (error) {
          console.log(error)
      }
  }

    fetchUserAuth();

    
    // Redirect if the user is already logged in
    // if(localStorage.getItem('userData')){
    //   if (!userData.isActiveSubscription && !urlSearchParamss.get('payment_intent')) {
    //     if(!userData.isComplementary){
    //       if(!userData.isTeamMember){
    //         navigate('/app/userPricing'); // Redirect to your main app page
    //       }
    //     }
    //   }
    // }
  }, [navigate]);

  return <>{children}</>;
};

export default ActiveSubscription;