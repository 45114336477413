import {React,Fragment,useState} from "react";
import './KindleList.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as fontIcons from '@fortawesome/free-solid-svg-icons';
import { faEllipsisVertical } from "@fortawesome/free-solid-svg-icons";
import {faChevronDown} from "@fortawesome/free-solid-svg-icons";
import TrashIcon from "../../../assets/icons/trash_icon.svg";
import CalenderIcon from '../../../assets/icons/calendar.svg'; 
import ArtBook from "../../../assets/images/artBook.png";
import Detox from "../../../assets/images/detox.png";
import Harry from "../../../assets/images/harry.png";
import Morrie from "../../../assets/images/morrie.png";
import Thief from "../../../assets/images/thief.png";
import Florence from "../../../assets/images/florence.png";
import Power from "../../../assets/images/power.png";
import Chanakya from "../../../assets/images/chanakya.png";
import Friends from "../../../assets/images/friends.png";
import Monk from "../../../assets/images/monk.png";
import EmailGrey from "../../../assets/icons/emailGrey.svg";
import { Menu, Transition } from '@headlessui/react';

function KindleList() {
  const filterDropDown = [
    { name: 'Most Recent', href: '#' },
    { name: 'New Highlights', href: '#' },
    { name: 'Ascending', href: '#' }, 
    { name: 'Descending', href: '#' },
  ]

  const filterDropDownselect = [
    { name: 'Select', href: '#' },
    { name: 'Select all', href: '#' },
  ]

  const [setFiltersText] = useState(filterDropDown[0].name);

  const [setFiltersSelectText] = useState(filterDropDownselect[0].name);

  const handleFilterText = (text) => {
      setFiltersText(text);
    }

  const handleFilterSelectText = (text) => {
    setFiltersSelectText(text);
  } 
           
    function classNames(...classes) {
     return classes.filter(Boolean).join(' ')
   }

  return(
    <div>
      <div className="kindleList-wholesection">
        <div className="featureprogress-content">Sample data only - This feature is in Progress…</div>
        <div className="kindleHeader">
          <div className="flex justify-between mb-3">
            <div className="flex items-center justify-end"> 
              <h2 className="text-xl mybuckets-content dark:text-[#fff]">Kindle Highlights</h2>
              <button style={{filter: 'drop-shadow(0px 10px 20px rgba(0, 0, 0, 0.161))'}} className="buttonpadding-content mx-8 text-[16px] rounded-full bg-[#FF6600] text-white pr-[32px]">
                <FontAwesomeIcon className="pl-[23px] pr-[17.73px]" icon={fontIcons.faPlus} />
                <span className="createbutton-context">Import Highlights</span>
              </button>  
              <div className="text-[14px] lg:text-[17px]"><a className="flex items-center" href="/"><img src={CalenderIcon} alt='calenderIcon' className="w-[22px] dark-mode-icon"/> <span className="createdtext-content pl-2 dark:text-[#bbbbbb]">Last Import on Jan 5, 2022 @ 05:00 AM</span></a> </div>             
              </div>          
            </div>
        </div>
        <hr />
        <div className="flex mt-[18px]">
          <div className="cards-filter flex justify-between">
            <div className="flex items-center">
              <div className="flex actionsHolder">            
                <div href="#" className="flex items-center">
                  <div className="kindlehighlight-content mr-4">Kindle Highlights<span className="kindlebg-content background-primary text-white rounded-full ml-4">22</span></div>
                  {/* <FontAwesomeIcon className="vertical-iconcontent text-[#707070] text-[22px] mr-4" icon={faEllipsisVertical} /> */}
                  <Menu as="div" className="relative flex-shrink-0">
                <div>
                  <Menu.Button className="flex rounded-full focus:outline-none ">
                    <div href="#" className="flex-shrink-0 rounded-full text-gray-400 hover:text-gray-500">
                      <strong><FontAwesomeIcon className="vertical-iconcontent text-[#707070] text-[22px] mr-4" icon={faEllipsisVertical} /></strong>
                    </div>
                  </Menu.Button>
                </div>
                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">                           
                    {filterDropDownselect.map((item) => (
                      <Menu.Item key={item.name}>
                        {({ active }) => (
                          <a
                            onClick={()=>{handleFilterSelectText(item.name)}}
                            href={item.href}
                            className={classNames(
                              active ? 'bg-gray-100' : '',
                              'block py-2 px-4 text-[13px] text-gray-700'
                            )}
                          >
                            {item.name}
                          </a>
                        )}
                      </Menu.Item>
                    ))}
                  </Menu.Items>
                </Transition>
              </Menu>
                  <img className="w-6 trash-icon" src={TrashIcon} alt="trashIcon"/>
                </div>           
              </div>
            </div>
          </div>
          <div className="ml-auto">
            <div className="ml-[auto] flex items-center justify-end">
              <span className="sorttext-content mx-3 dark:text-[#acacac]">Sort By</span>
              <Menu as="div" className="relative flex-shrink-0">
                <div>
                  <Menu.Button className="flex rounded-full focus:outline-none ">
                    <div href="#" className="flex-shrink-0 rounded-full text-gray-400 hover:text-gray-500">
                      <strong className="createdtext-content pr-4">Most Recent</strong>
                      <FontAwesomeIcon style={{fontSize:'12px',color:'#707070'}} icon={faChevronDown} />
                    </div>
                  </Menu.Button>
                </div>
                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">                           
                    {filterDropDown.map((item) => (
                      <Menu.Item key={item.name}>
                        {({ active }) => (
                          <a
                            onClick={()=>{handleFilterText(item.name)}}
                            href={item.href}
                            className={classNames(
                              active ? 'bg-gray-100' : '',
                              'block py-2 px-4 text-[13px] text-gray-700'
                            )}
                          >
                            {item.name}
                          </a>
                        )}
                      </Menu.Item>
                    ))}
                  </Menu.Items>
                </Transition>
              </Menu>
            </div> 
          </div>
        </div>
        <div className="flex rowkindle-content mt-6">
          {/* <a href="/app/kindledetail">  
            <div className="cardHolder rounded-[14px] overflow-hidden mb-3 break-inside">
              <div className="flex justify-between w-full px-4 pt-3 pb-1">
                <div className="inputCheck">
                  <input type="checkbox" />
                </div>                        
              </div>
              <div className="cardHeader flex items-center pb-2 px-4">
                <div className="Cardtitle w-3/4">
                  <h3 className="social-textcontent">The ART of Fashion Journal by Eila Mell</h3>
                </div>
                <div className="iconsRight flex iconsalign-content">
                  <FontAwesomeIcon className="vertical-iconcontent text-[#707070] text-[22px] ml-4" icon={faEllipsisVertical} />
                </div>
              </div>
              <div className="kindle-card"> 
                <span>Highlights: 2</span>
                <img className="w-full kindleimg-content" src={ArtBook} alt="artFashion"/>
                <div className="kindlewhole-bgcontent flex w-full">
                  <div className="kindlelist-box bg-green w-[50%]"></div>
                  <div className="kindlelist-box ml-[2px] bg-afc604 w-[50%]"></div>
                </div> 
            </div>
            </div>
          </a>  */}
          <a href="/app/kindledetail">
            <div className="cardHolderskindle">             
              <div className="kindle-card"> 
                <div className="kindlenumber-content">5</div>
                <div className="kindleemail-content"><img src={EmailGrey} alt="emailGrey" /></div>
                <img className="kindleimg-content" src={ArtBook} alt="artBook"/>
                <div className="kindlewhole-bgcontent flex">
                  <div className="kindlelist-box bg-green"></div>
                  <div className="kindlelist-box ml-[2px] bg-afc604"></div>
                </div> 
                <div className="cardHeader flex items-center">
                  <div className="tooltip">
                    <span className="tooltipkindle-text bucket">
                      <p>The Art of saying no by Damon Zahariades</p>
                    </span>
                    <div className="Cardtitle">
                      <h3 className="social-textcontent">The Art of saying no by Damon Zahariades</h3>
                    </div>
                  </div>
                </div>
                {/* <div className="highlight-content">Highlights: 2</div> */}
              </div>
            </div>
          </a>  
          <a href="/app/kindledetail">
            <div className="cardHolderskindle">            
              <div className="kindle-card"> 
                <div className="kindlenumber-content">25</div>
                <div className="kindleemail-content"><img src={EmailGrey} alt="emailGrey" /></div>
                <img className="kindleimg-content" src={Detox} alt="detox"/>
                <div className="kindlewhole-bgcontent flex">
                  <div className="kindlelist-twobox bg-green"></div>
                  <div className="kindlelist-twobox ml-[2px] bg-afc604"></div>
                  <div className="kindlelist-twobox ml-[2px] cardcolorbg-content"></div>
                  <div className="kindlelist-twobox ml-[2px] bg-0fd1bd"></div>
                </div> 
                <div className="cardHeader flex items-center">
                  <div className="tooltip">
                    <span className="tooltipkindle-text bucket">
                      <p>Dopamine Detox by Thibaut Meurisse</p>
                    </span>
                    <div className="Cardtitle">
                      <h3 className="social-textcontent">Dopamine Detox by Thibaut Meurisse</h3>
                    </div>
                  </div>
                </div>
                {/* <div className="highlight-content">Highlights: 4</div> */}
              </div>
            </div>
          </a> 
          <a href="/app/kindledetail">
            <div className="cardHolderskindle">            
              <div className="kindle-card"> 
                <div className="kindlenumber-content">17</div>
                <img className="kindleimg-content" src={Harry} alt="harry"/>
                <div className="kindlewhole-bgcontent flex">
                  <div className="kindlelist-box bg-green"></div>
                  <div className="kindlelist-box ml-[2px] bg-afc604"></div>
                </div> 
                <div className="cardHeader flex items-center">
                  <div className="tooltip">
                    <span className="tooltipkindle-text bucket">
                      <p>Harry Potter and the philosophers Stone</p>
                    </span>
                    <div className="Cardtitle">
                      <h3 className="social-textcontent">Harry Potter and the philosophers Stone</h3>
                    </div>
                  </div>
                </div>
                {/* <div className="highlight-content">Highlights: 2</div> */}
              </div>
            </div>
          </a> 
          <a href="/app/kindledetail">
            <div className="cardHolderskindle">            
              <div className="kindle-card"> 
                <div className="kindlenumber-content">17</div>
                <img className="kindleimg-content" src={Morrie} alt="morrie"/>
                <div className="kindlewhole-bgcontent flex">
                  <div className="kindlelist-onebox cardcolorbg-content"></div>
                </div> 
                <div className="cardHeader flex items-center">
                  <div className="tooltip">
                    <span className="tooltipkindle-text bucket">
                      <p>Tuesdays with Morrie by Mitch Albom</p>
                    </span>
                    <div className="Cardtitle">
                      <h3 className="social-textcontent">Tuesdays with Morrie by Mitch Albom</h3>
                    </div>
                  </div>
                </div>
                {/* <div className="highlight-content">Highlights: 1</div> */}
              </div>
            </div>
          </a> 
          <a href="/app/kindledetail">
            <div className="cardHolderskindle">            
              <div className="kindle-card"> 
                <div className="kindlenumber-content">6</div>
                <img className="kindleimg-content" src={Thief} alt="thief"/>
                <div className="kindlewhole-bgcontent flex">
                  <div className="kindlelist-onebox cardcolorbg-content"></div>
                </div> 
                <div className="cardHeader flex items-center">
                  <div className="tooltip">
                    <span className="tooltipkindle-text bucket">
                      <p>The Book Thief by Markus Zusak</p>
                    </span>
                    <div className="Cardtitle">
                      <h3 className="social-textcontent">The Book Thief by Markus Zusak</h3>
                    </div>
                  </div>
                </div>
                {/* <div className="highlight-content">Highlights: 1</div> */}
              </div>
            </div>
          </a> 
          <a href="/app/kindledetail">
            <div className="cardHolderskindle">            
              <div className="kindle-card"> 
                <div className="kindlenumber-content">5</div>
                <img className="kindleimg-content" src={Florence} alt="florence"/>
                <div className="kindlewhole-bgcontent flex">
                  <div className="kindlelist-twobox bg-green"></div>
                  <div className="kindlelist-twobox ml-[2px] bg-afc604"></div>
                  <div className="kindlelist-twobox ml-[2px] cardcolorbg-content"></div>
                  <div className="kindlelist-twobox ml-[2px] bg-0fd1bd"></div>
                </div> 
                <div className="cardHeader flex items-center">
                  <div className="tooltip">
                    <span className="tooltipkindle-text bucket">
                      <p>Florence Nightingale: A Life from beginning to end</p>
                    </span>
                    <div className="Cardtitle">
                      <h3 className="social-textcontent">Florence Nightingale: A Life from beginning to end</h3>
                    </div>
                  </div>
                </div>
                {/* <div className="highlight-content">Highlights: 4</div> */}
              </div>
            </div>
          </a> 
          <a href="/app/kindledetail">
            <div className="cardHolderskindle">            
              <div className="kindle-card"> 
                <div className="kindlenumber-content">4</div>
                <img className="kindleimg-content" src={Power} alt="power"/>
                <div className="kindlewhole-bgcontent flex">
                  <div className="kindlelist-box bg-green"></div>
                  <div className="kindlelist-box ml-[2px] bg-afc604"></div>
                </div> 
                <div className="cardHeader flex items-center">
                  <div className="tooltip">
                    <span className="tooltipkindle-text bucket">
                      <p>The Power of Subconscious Mind by Joseph Murphy</p>
                    </span>
                    <div className="Cardtitle">
                      <h3 className="social-textcontent">The Power of Subconscious Mind by Joseph Murphy</h3>
                    </div>
                  </div>
                </div>
                {/* <div className="highlight-content">Highlights: 2</div> */}
              </div>
            </div>
          </a> 
          <a href="/app/kindledetail">
            <div className="cardHolderskindle">            
              <div className="kindle-card"> 
                <div className="kindlenumber-content">4</div>
                <img className="kindleimg-content" src={Chanakya} alt="chanakya"/>
                <div className="kindlewhole-bgcontent flex">
                  <div className="kindlelist-box bg-green"></div>
                  <div className="kindlelist-box ml-[2px] bg-afc604"></div>
                </div> 
                <div className="cardHeader flex items-center">
                  <div className="tooltip">
                    <div className="Cardtitle">
                      <h3 className="social-textcontent">Chanakya by Yagya sharma</h3>
                    </div>
                  </div>
                </div>
                {/* <div className="highlight-content">Highlights: 2</div> */}
              </div>
            </div>
          </a> 
          <a href="/app/kindledetail">
            <div className="cardHolderskindle">            
              <div className="kindle-card">
                <div className="kindlenumber-content">6</div> 
                <img className="kindleimg-content" src={Friends} alt="friends"/>
                <div className="kindlewhole-bgcontent flex">
                  <div className="kindlelist-box bg-green"></div>
                  <div className="kindlelist-box ml-[2px] bg-afc604"></div>
                </div> 
                <div className="cardHeader flex items-center">
                  <div className="tooltip">
                    <span className="tooltipkindle-text bucket">
                      <p>How to win friends and Influence People by Dale Carnegie</p>
                    </span>
                    <div className="Cardtitle">
                      <h3 className="social-textcontent">How to win friends and Influence People by Dale Carnegie</h3>
                    </div>
                  </div>
                </div>
                {/* <div className="highlight-content">Highlights: 2</div> */}
              </div>
            </div>
          </a> 
          <a href="/app/kindledetail">
            <div className="cardHolderskindle">            
              <div className="kindle-card"> 
                <div className="kindlenumber-content">5</div>
                <img className="kindleimg-content" src={Monk} alt="Monk"/>
                <div className="kindlewhole-bgcontent flex">
                  <div className="kindlelist-box bg-green"></div>
                  <div className="kindlelist-box ml-[2px] bg-afc604"></div>
                </div> 
                <div className="cardHeader flex items-center">
                  <div className="tooltip">
                    <span className="tooltipkindle-text bucket">
                      <p>The Monk who sold his ferrari by Robin Sharma</p>
                    </span>
                    <div className="Cardtitle">
                      <h3 className="social-textcontent">The Monk who sold his ferrari by Robin Sharma</h3>
                    </div>
                  </div>
                </div>
                {/* <div className="highlight-content">Highlights: 2</div> */}
              </div>
            </div>
          </a> 
          <a href="/app/kindledetail">
            <div className="cardHolderskindle">            
              <div className="kindle-card">
                <div className="kindlenumber-content">17</div> 
                <img className="kindleimg-content" src={Chanakya} alt="chanakya"/>
                <div className="kindlewhole-bgcontent flex">
                  <div className="kindlelist-box bg-green"></div>
                  <div className="kindlelist-box ml-[2px] bg-afc604"></div>
                </div> 
                <div className="cardHeader flex items-center">
                  <div className="tooltip">
                    <div className="Cardtitle">
                      <h3 className="social-textcontent">Chanakya by Yagya sharma</h3>
                    </div>
                  </div>
                </div>
                {/* <div className="highlight-content">Highlights: 2</div> */}
              </div>
            </div>
          </a> 
          <a href="/app/kindledetail">
            <div className="cardHolderskindle">            
              <div className="kindle-card"> 
                <div className="kindlenumber-content">17</div>
                <img className="kindleimg-content" src={Friends} alt="friends"/>
                <div className="kindlewhole-bgcontent flex">
                  <div className="kindlelist-box bg-green"></div>
                  <div className="kindlelist-box ml-[2px] bg-afc604"></div>
                </div> 
                <div className="cardHeader flex items-center">
                  <div className="tooltip">
                    <span className="tooltipkindle-text bucket">
                      <p>How to win friends and Influence People by Dale Carnegie</p>
                    </span>
                    <div className="Cardtitle">
                      <h3 className="social-textcontent">How to win friends and Influence People by Dale Carnegie</h3>
                    </div>
                  </div>
                </div>
                {/* <div className="highlight-content">Highlights: 2</div> */}
              </div>
            </div>
          </a> 
          <a href="/app/kindledetail">
            <div className="cardHolderskindle">            
              <div className="kindle-card"> 
                <div className="kindlenumber-content">6</div>
                <img className="kindleimg-content" src={Monk} alt="Monk"/>
                <div className="kindlewhole-bgcontent flex">
                  <div className="kindlelist-box bg-green"></div>
                  <div className="kindlelist-box ml-[2px] bg-afc604"></div>
                </div> 
                <div className="cardHeader flex items-center">
                  <div className="tooltip">
                    <span className="tooltipkindle-text bucket">
                      <p>The Monk who sold his ferrari by Robin Sharma</p>
                    </span>
                    <div className="Cardtitle">
                      <h3 className="social-textcontent">The Monk who sold his ferrari by Robin Sharma</h3>
                    </div>
                  </div>
                </div>
                {/* <div className="highlight-content">Highlights: 2</div> */}
              </div>
            </div>
          </a> 
          <a href="/app/kindledetail">
            <div className="cardHolderskindle">            
              <div className="kindle-card"> 
                <div className="kindlenumber-content">4</div>
                <img className="kindleimg-content" src={ArtBook} alt="artBook"/>
                <div className="kindlewhole-bgcontent flex">
                  <div className="kindlelist-box bg-green"></div>
                  <div className="kindlelist-box ml-[2px] bg-afc604"></div>
                </div> 
                <div className="cardHeader flex items-center">
                  <div className="tooltip">
                    <span className="tooltipkindle-text bucket">
                      <p>The Art of saying no by Damon Zahariades</p>
                    </span>
                    <div className="Cardtitle">
                      <h3 className="social-textcontent">The Art of saying no by Damon Zahariades</h3>
                    </div>
                  </div>
                </div>
                {/* <div className="highlight-content">Highlights: 2</div> */}
              </div>
            </div>
          </a> 
          <a href="/app/kindledetail">
            <div className="cardHolderskindle">            
              <div className="kindle-card"> 
                <div className="kindlenumber-content">6</div>
                <img className="kindleimg-content" src={Detox} alt="detox"/>
                <div className="kindlewhole-bgcontent flex">
                  <div className="kindlelist-twobox bg-green"></div>
                  <div className="kindlelist-twobox ml-[2px] bg-afc604"></div>
                  <div className="kindlelist-twobox ml-[2px] cardcolorbg-content"></div>
                  <div className="kindlelist-twobox ml-[2px] bg-0fd1bd"></div>
                </div> 
                <div className="cardHeader flex items-center">
                  <div className="tooltip">
                    <span className="tooltipkindle-text bucket">
                      <p>Dopamine Detox by Thibaut Meurisse</p>
                    </span>
                    <div className="Cardtitle">
                      <h3 className="social-textcontent">Dopamine Detox by Thibaut Meurisse</h3>
                    </div>
                  </div>
                </div>
                {/* <div className="highlight-content">Highlights: 4</div> */}
              </div>
            </div>
          </a> 
          <a href="/app/kindledetail">
            <div className="cardHolderskindle">            
              <div className="kindle-card"> 
                <div className="kindlenumber-content">17</div>
                <img className="kindleimg-content" src={Harry} alt="harry"/>
                <div className="kindlewhole-bgcontent flex">
                  <div className="kindlelist-box bg-green"></div>
                  <div className="kindlelist-box ml-[2px] bg-afc604"></div>
                </div> 
                <div className="cardHeader flex items-center">
                  <div className="tooltip">
                    <span className="tooltipkindle-text bucket">
                      <p>Harry Potter and the philosophers Stone</p>
                    </span>
                    <div className="Cardtitle">
                      <h3 className="social-textcontent">Harry Potter and the philosophers Stone</h3>
                    </div>
                  </div>
                </div>
                {/* <div className="highlight-content">Highlights: 2</div> */}
              </div>
            </div>
          </a> 
          <a href="/app/kindledetail">
            <div className="cardHolderskindle">            
              <div className="kindle-card"> 
                <div className="kindlenumber-content">5</div>
                <img className="kindleimg-content" src={Morrie} alt="morrie"/>
                <div className="kindlewhole-bgcontent flex">
                  <div className="kindlelist-onebox cardcolorbg-content"></div>
                </div> 
                <div className="cardHeader flex items-center">
                  <div className="tooltip">
                    <span className="tooltipkindle-text bucket">
                      <p>Tuesdays with Morrie by Mitch Albom</p>
                    </span>
                    <div className="Cardtitle">
                      <h3 className="social-textcontent">Tuesdays with Morrie by Mitch Albom</h3>
                    </div>
                  </div>
                </div>
                {/* <div className="highlight-content">Highlights: 1</div> */}
              </div>
            </div>
          </a> 
          <a href="/app/kindledetail">
            <div className="cardHolderskindle">            
              <div className="kindle-card"> 
                <div className="kindlenumber-content">5</div>
                <img className="kindleimg-content" src={Thief} alt="thief"/>
                <div className="kindlewhole-bgcontent flex">
                  <div className="kindlelist-onebox cardcolorbg-content"></div>
                </div> 
                <div className="cardHeader flex items-center">
                  <div className="tooltip">
                    <span className="tooltipkindle-text bucket">
                      <p>The Book Thief by Markus Zusak</p>
                    </span>
                    <div className="Cardtitle">
                      <h3 className="social-textcontent">The Book Thief by Markus Zusak</h3>
                    </div>
                  </div>
                </div>
                {/* <div className="highlight-content">Highlights: 1</div> */}
              </div>
            </div>
          </a>
          <a href="/app/kindledetail">
            <div className="cardHolderskindle">            
              <div className="kindle-card"> 
                <div className="kindlenumber-content">4</div>
                <img className="kindleimg-content" src={Florence} alt="florence"/>
                <div className="kindlewhole-bgcontent flex">
                  <div className="kindlelist-twobox bg-green"></div>
                  <div className="kindlelist-twobox ml-[2px] bg-afc604"></div>
                  <div className="kindlelist-twobox ml-[2px] cardcolorbg-content"></div>
                  <div className="kindlelist-twobox ml-[2px] bg-0fd1bd"></div>
                </div> 
                <div className="cardHeader flex items-center">
                  <div className="tooltip">
                    <span className="tooltipkindle-text bucket">
                      <p>Florence Nightingale: A Life from beginning to end</p>
                    </span>
                    <div className="Cardtitle">
                      <h3 className="social-textcontent">Florence Nightingale: A Life from beginning to end</h3>
                    </div>
                  </div>
                </div>
                {/* <div className="highlight-content">Highlights: 4</div> */}
              </div>
            </div>
          </a> 
          <a href="/app/kindledetail">
            <div className="cardHolderskindle">            
              <div className="kindle-card"> 
                <div className="kindlenumber-content">4</div>
                <img className="kindleimg-content" src={Power} alt="power"/>
                <div className="kindlewhole-bgcontent flex">
                  <div className="kindlelist-box bg-green"></div>
                  <div className="kindlelist-box ml-[2px] bg-afc604"></div>
                </div> 
                <div className="cardHeader flex items-center">
                  <div className="tooltip">
                    <span className="tooltipkindle-text bucket">
                      <p>The Power of Subconscious Mind by Joseph Murphy</p>
                    </span>
                    <div className="Cardtitle">
                      <h3 className="social-textcontent">The Power of Subconscious Mind by Joseph Murphy</h3>
                    </div>
                  </div>
                </div>
                {/* <div className="highlight-content">Highlights: 2</div> */}
              </div>
            </div>
          </a> 
          <a href="/app/kindledetail">
            <div className="cardHolderskindle">            
              <div className="kindle-card"> 
                <div className="kindlenumber-content">5</div>
                <img className="kindleimg-content" src={Thief} alt="thief"/>
                <div className="kindlewhole-bgcontent flex">
                  <div className="kindlelist-onebox cardcolorbg-content"></div>
                </div> 
                <div className="cardHeader flex items-center">
                  <div className="tooltip">
                    <span className="tooltipkindle-text bucket">
                      <p>The Book Thief by Markus Zusak</p>
                    </span>
                    <div className="Cardtitle">
                      <h3 className="social-textcontent">The Book Thief by Markus Zusak</h3>
                    </div>
                  </div>
                </div>
                {/* <div className="highlight-content">Highlights: 1</div> */}
              </div>
            </div>
          </a> 
        </div>
      </div>
      <div className="kindlelist-mblsection">
        <div className="kindleHeader">
        <div className="featureprogress-content pl-[0px]">Sample data only - This feature is in Progress…</div>
          <div className="flex justify-between spacekindle-seccontent mb-3">
            <div className="flex items-center justify-end"> 
              <h2 className="text-xl mybuckets-content dark:text-[#fff]">Kindle Highlights</h2>
              <button style={{filter: 'drop-shadow(0px 10px 20px rgba(0, 0, 0, 0.161))'}} className="buttonpadding-content text-[16px] rounded-full bg-[#FF6600] text-white pr-[32px]">
                <FontAwesomeIcon className="pl-[23px] pr-[17.73px]" icon={fontIcons.faPlus} />
                <span className="createbutton-context">Import Highlights</span>
              </button>                
            </div>          
          </div>
          <div className="text-[14px] lg:text-[17px] mb-2"><a className="flex items-center" href="/"><img src={CalenderIcon} alt='calenderIcon' className="w-[22px] dark-mode-icon"/> <span className="createdtext-content pl-2 dark:text-[#bbbbbb]">Last Import on Jan 5, 2022 @ 05:00 AM</span></a> </div>             
        </div>
        <hr/>
        <div className="flex rowkindle-content mt-4">
          {/* <a href="/app/kindledetail">  
            <div className="cardHolder rounded-[14px] overflow-hidden mb-3 break-inside">
              <div className="flex justify-between w-full px-4 pt-3 pb-1">
                <div className="inputCheck">
                  <input type="checkbox" />
                </div>                        
              </div>
              <div className="cardHeader flex items-center pb-2 px-4">
                <div className="Cardtitle w-3/4">
                  <h3 className="social-textcontent">The ART of Fashion Journal by Eila Mell</h3>
                </div>
                <div className="iconsRight flex iconsalign-content">
                  <FontAwesomeIcon className="vertical-iconcontent text-[#707070] text-[22px] ml-4" icon={faEllipsisVertical} />
                </div>
              </div>
              <div className="kindle-card"> 
                <span>Highlights: 2</span>
                <img className="w-full kindleimg-content" src={ArtBook} alt="artFashion"/>
                <div className="kindlewhole-bgcontent flex w-full">
                  <div className="kindlelist-box bg-green w-[50%]"></div>
                  <div className="kindlelist-box ml-[2px] bg-afc604 w-[50%]"></div>
                </div> 
            </div>
            </div>
          </a>  */}
          
            <div className="cardHolderskindle">            
              <div className="kindle-card"> 
                <div className="kindlenumber-content">5</div>
                <img className="kindleimg-content" src={ArtBook} alt="artBook"/>
                <div className="kindlewhole-bgcontent flex">
                  <div className="kindlelist-box bg-green"></div>
                  <div className="kindlelist-box ml-[2px] bg-afc604"></div>
                </div> 
                <div className="cardHeader flex items-center">
                  <div className="tooltip">
                    <span className="tooltipkindle-text bucket">
                      <p>The Art of saying no by Damon Zahariades</p>
                    </span>
                    <div className="Cardtitle">
                      <h3 className="social-textcontent">The Art of saying no by Damon Zahariades</h3>
                    </div>
                  </div>
                </div>
                {/* <div className="highlight-content">Highlights: 2</div> */}
              </div>
            </div>
           
          
            <div className="cardHolderskindle">            
              <div className="kindle-card"> 
                <div className="kindlenumber-content">25</div>
                <img className="kindleimg-content" src={Detox} alt="detox"/>
                <div className="kindlewhole-bgcontent flex">
                  <div className="kindlelist-twobox bg-green"></div>
                  <div className="kindlelist-twobox ml-[2px] bg-afc604"></div>
                  <div className="kindlelist-twobox ml-[2px] cardcolorbg-content"></div>
                  <div className="kindlelist-twobox ml-[2px] bg-0fd1bd"></div>
                </div> 
                <div className="cardHeader flex items-center">
                  <div className="tooltip">
                    <span className="tooltipkindle-text bucket">
                      <p>Dopamine Detox by Thibaut Meurisse</p>
                    </span>
                    <div className="Cardtitle">
                      <h3 className="social-textcontent">Dopamine Detox by Thibaut Meurisse</h3>
                    </div>
                  </div>
                </div>
                {/* <div className="highlight-content">Highlights: 4</div> */}
              </div>
            </div>
           
          
            <div className="cardHolderskindle">            
              <div className="kindle-card"> 
                <div className="kindlenumber-content">17</div>
                <img className="kindleimg-content" src={Harry} alt="harry"/>
                <div className="kindlewhole-bgcontent flex">
                  <div className="kindlelist-box bg-green"></div>
                  <div className="kindlelist-box ml-[2px] bg-afc604"></div>
                </div> 
                <div className="cardHeader flex items-center">
                  <div className="tooltip">
                    <span className="tooltipkindle-text bucket">
                      <p>Harry Potter and the philosophers Stone</p>
                    </span>
                    <div className="Cardtitle">
                      <h3 className="social-textcontent">Harry Potter and the philosophers Stone</h3>
                    </div>
                  </div>
                </div>
                {/* <div className="highlight-content">Highlights: 2</div> */}
              </div>
            </div>
           
          
            <div className="cardHolderskindle">            
              <div className="kindle-card"> 
                <div className="kindlenumber-content">17</div>
                <img className="kindleimg-content" src={Morrie} alt="morrie"/>
                <div className="kindlewhole-bgcontent flex">
                  <div className="kindlelist-onebox cardcolorbg-content"></div>
                </div> 
                <div className="cardHeader flex items-center">
                  <div className="tooltip">
                    <span className="tooltipkindle-text bucket">
                      <p>Tuesdays with Morrie by Mitch Albom</p>
                    </span>
                    <div className="Cardtitle">
                      <h3 className="social-textcontent">Tuesdays with Morrie by Mitch Albom</h3>
                    </div>
                  </div>
                </div>
                {/* <div className="highlight-content">Highlights: 1</div> */}
              </div>
            </div>
           
          
            <div className="cardHolderskindle">            
              <div className="kindle-card"> 
                <div className="kindlenumber-content">17</div>
                <img className="kindleimg-content" src={Thief} alt="thief"/>
                <div className="kindlewhole-bgcontent flex">
                  <div className="kindlelist-onebox cardcolorbg-content"></div>
                </div> 
                <div className="cardHeader flex items-center">
                  <div className="tooltip">
                    <span className="tooltipkindle-text bucket">
                      <p>The Book Thief by Markus Zusak</p>
                    </span>
                    <div className="Cardtitle">
                      <h3 className="social-textcontent">The Book Thief by Markus Zusak</h3>
                    </div>
                  </div>
                </div>
                {/* <div className="highlight-content">Highlights: 1</div> */}
              </div>
            </div>
           
          
            <div className="cardHolderskindle">            
              <div className="kindle-card"> 
                <div className="kindlenumber-content">17</div>
                <img className="kindleimg-content" src={Florence} alt="florence"/>
                <div className="kindlewhole-bgcontent flex">
                  <div className="kindlelist-twobox bg-green"></div>
                  <div className="kindlelist-twobox ml-[2px] bg-afc604"></div>
                  <div className="kindlelist-twobox ml-[2px] cardcolorbg-content"></div>
                  <div className="kindlelist-twobox ml-[2px] bg-0fd1bd"></div>
                </div> 
                <div className="cardHeader flex items-center">
                  <div className="tooltip">
                    <span className="tooltipkindle-text bucket">
                      <p>Florence Nightingale: A Life from beginning to end</p>
                    </span>
                    <div className="Cardtitle">
                      <h3 className="social-textcontent">Florence Nightingale: A Life from beginning to end</h3>
                    </div>
                  </div>
                </div>
                {/* <div className="highlight-content">Highlights: 4</div> */}
              </div>
            </div>
           
          
            <div className="cardHolderskindle">            
              <div className="kindle-card"> 
                <div className="kindlenumber-content">4</div>
                <img className="kindleimg-content" src={Power} alt="power"/>
                <div className="kindlewhole-bgcontent flex">
                  <div className="kindlelist-box bg-green"></div>
                  <div className="kindlelist-box ml-[2px] bg-afc604"></div>
                </div> 
                <div className="cardHeader flex items-center">
                  <div className="tooltip">
                    <span className="tooltipkindle-text bucket">
                      <p>The Power of Subconscious Mind by Joseph Murphy</p>
                    </span>
                    <div className="Cardtitle">
                      <h3 className="social-textcontent">The Power of Subconscious Mind by Joseph Murphy</h3>
                    </div>
                  </div>
                </div>
                {/* <div className="highlight-content">Highlights: 2</div> */}
              </div>
            </div>
           
          
            <div className="cardHolderskindle">            
              <div className="kindle-card"> 
                <div className="kindlenumber-content">5</div>
                <img className="kindleimg-content" src={Chanakya} alt="chanakya"/>
                <div className="kindlewhole-bgcontent flex">
                  <div className="kindlelist-box bg-green"></div>
                  <div className="kindlelist-box ml-[2px] bg-afc604"></div>
                </div> 
                <div className="cardHeader flex items-center">
                  <div className="tooltip">
                    <div className="Cardtitle">
                      <h3 className="social-textcontent">Chanakya by Yagya sharma</h3>
                    </div>
                  </div>
                </div>
                {/* <div className="highlight-content">Highlights: 2</div> */}
              </div>
            </div>
           
          
            <div className="cardHolderskindle">            
              <div className="kindle-card"> 
                <div className="kindlenumber-content">5</div>
                <img className="kindleimg-content" src={Friends} alt="friends"/>
                <div className="kindlewhole-bgcontent flex">
                  <div className="kindlelist-box bg-green"></div>
                  <div className="kindlelist-box ml-[2px] bg-afc604"></div>
                </div> 
                <div className="cardHeader flex items-center">
                  <div className="tooltip">
                    <span className="tooltipkindle-text bucket">
                      <p>How to win friends and Influence People by Dale Carnegie</p>
                    </span>
                    <div className="Cardtitle">
                      <h3 className="social-textcontent">How to win friends and Influence People by Dale Carnegie</h3>
                    </div>
                  </div>
                </div>
                {/* <div className="highlight-content">Highlights: 2</div> */}
              </div>
            </div>
           
          
            <div className="cardHolderskindle">            
              <div className="kindle-card"> 
                <div className="kindlenumber-content">5</div>
                <img className="kindleimg-content" src={Monk} alt="Monk"/>
                <div className="kindlewhole-bgcontent flex">
                  <div className="kindlelist-box bg-green"></div>
                  <div className="kindlelist-box ml-[2px] bg-afc604"></div>
                </div> 
                <div className="cardHeader flex items-center">
                  <div className="tooltip">
                    <span className="tooltipkindle-text bucket">
                      <p>The Monk who sold his ferrari by Robin Sharma</p>
                    </span>
                    <div className="Cardtitle">
                      <h3 className="social-textcontent">The Monk who sold his ferrari by Robin Sharma</h3>
                    </div>
                  </div>
                </div>
                {/* <div className="highlight-content">Highlights: 2</div> */}
              </div>
            </div>
           
          
            <div className="cardHolderskindle">            
              <div className="kindle-card"> 
                <div className="kindlenumber-content">5</div>
                <img className="kindleimg-content" src={Chanakya} alt="chanakya"/>
                <div className="kindlewhole-bgcontent flex">
                  <div className="kindlelist-box bg-green"></div>
                  <div className="kindlelist-box ml-[2px] bg-afc604"></div>
                </div> 
                <div className="cardHeader flex items-center">
                  <div className="tooltip">
                    <div className="Cardtitle">
                      <h3 className="social-textcontent">Chanakya by Yagya sharma</h3>
                    </div>
                  </div>
                </div>
                {/* <div className="highlight-content">Highlights: 2</div> */}
              </div>
            </div>
           
          
            <div className="cardHolderskindle">            
              <div className="kindle-card"> 
                <div className="kindlenumber-content">17</div>
                <img className="kindleimg-content" src={Friends} alt="friends"/>
                <div className="kindlewhole-bgcontent flex">
                  <div className="kindlelist-box bg-green"></div>
                  <div className="kindlelist-box ml-[2px] bg-afc604"></div>
                </div> 
                <div className="cardHeader flex items-center">
                  <div className="tooltip">
                    <span className="tooltipkindle-text bucket">
                      <p>How to win friends and Influence People by Dale Carnegie</p>
                    </span>
                    <div className="Cardtitle">
                      <h3 className="social-textcontent">How to win friends and Influence People by Dale Carnegie</h3>
                    </div>
                  </div>
                </div>
                {/* <div className="highlight-content">Highlights: 2</div> */}
              </div>
            </div>
           
          
            <div className="cardHolderskindle">            
              <div className="kindle-card"> 
                <div className="kindlenumber-content">17</div>
                <img className="kindleimg-content" src={Monk} alt="Monk"/>
                <div className="kindlewhole-bgcontent flex">
                  <div className="kindlelist-box bg-green"></div>
                  <div className="kindlelist-box ml-[2px] bg-afc604"></div>
                </div> 
                <div className="cardHeader flex items-center">
                  <div className="tooltip">
                    <span className="tooltipkindle-text bucket">
                      <p>The Monk who sold his ferrari by Robin Sharma</p>
                    </span>
                    <div className="Cardtitle">
                      <h3 className="social-textcontent">The Monk who sold his ferrari by Robin Sharma</h3>
                    </div>
                  </div>
                </div>
                {/* <div className="highlight-content">Highlights: 2</div> */}
              </div>
            </div>
           
          
            <div className="cardHolderskindle">            
              <div className="kindle-card"> 
                <div className="kindlenumber-content">17</div>
                <img className="kindleimg-content" src={ArtBook} alt="artBook"/>
                <div className="kindlewhole-bgcontent flex">
                  <div className="kindlelist-box bg-green"></div>
                  <div className="kindlelist-box ml-[2px] bg-afc604"></div>
                </div> 
                <div className="cardHeader flex items-center">
                  <div className="tooltip">
                    <span className="tooltipkindle-text bucket">
                      <p>The Art of saying no by Damon Zahariades</p>
                    </span>
                    <div className="Cardtitle">
                      <h3 className="social-textcontent">The Art of saying no by Damon Zahariades</h3>
                    </div>
                  </div>
                </div>
                {/* <div className="highlight-content">Highlights: 2</div> */}
              </div>
            </div>
           
          
            <div className="cardHolderskindle">            
              <div className="kindle-card"> 
                <div className="kindlenumber-content">5</div>
                <img className="kindleimg-content" src={Detox} alt="detox"/>
                <div className="kindlewhole-bgcontent flex">
                  <div className="kindlelist-twobox bg-green"></div>
                  <div className="kindlelist-twobox ml-[2px] bg-afc604"></div>
                  <div className="kindlelist-twobox ml-[2px] cardcolorbg-content"></div>
                  <div className="kindlelist-twobox ml-[2px] bg-0fd1bd"></div>
                </div> 
                <div className="cardHeader flex items-center">
                  <div className="tooltip">
                    <span className="tooltipkindle-text bucket">
                      <p>Dopamine Detox by Thibaut Meurisse</p>
                    </span>
                    <div className="Cardtitle">
                      <h3 className="social-textcontent">Dopamine Detox by Thibaut Meurisse</h3>
                    </div>
                  </div>
                </div>
                {/* <div className="highlight-content">Highlights: 4</div> */}
              </div>
            </div>
           
          
            <div className="cardHolderskindle">            
              <div className="kindle-card"> 
                <div className="kindlenumber-content">5</div>
                <img className="kindleimg-content" src={Harry} alt="harry"/>
                <div className="kindlewhole-bgcontent flex">
                  <div className="kindlelist-box bg-green"></div>
                  <div className="kindlelist-box ml-[2px] bg-afc604"></div>
                </div> 
                <div className="cardHeader flex items-center">
                  <div className="tooltip">
                    <span className="tooltipkindle-text bucket">
                      <p>Harry Potter and the philosophers Stone</p>
                    </span>
                    <div className="Cardtitle">
                      <h3 className="social-textcontent">Harry Potter and the philosophers Stone</h3>
                    </div>
                  </div>
                </div>
                {/* <div className="highlight-content">Highlights: 2</div> */}
              </div>
            </div>
           
          
            <div className="cardHolderskindle">            
              <div className="kindle-card"> 
                <div className="kindlenumber-content">5</div>
                <img className="kindleimg-content" src={Morrie} alt="morrie"/>
                <div className="kindlewhole-bgcontent flex">
                  <div className="kindlelist-onebox cardcolorbg-content"></div>
                </div> 
                <div className="cardHeader flex items-center">
                  <div className="tooltip">
                    <span className="tooltipkindle-text bucket">
                      <p>Tuesdays with Morrie by Mitch Albom</p>
                    </span>
                    <div className="Cardtitle">
                      <h3 className="social-textcontent">Tuesdays with Morrie by Mitch Albom</h3>
                    </div>
                  </div>
                </div>
                {/* <div className="highlight-content">Highlights: 1</div> */}
              </div>
            </div>
           
          
            <div className="cardHolderskindle">            
              <div className="kindle-card"> 
                <div className="kindlenumber-content">4</div>
                <img className="kindleimg-content" src={Thief} alt="thief"/>
                <div className="kindlewhole-bgcontent flex">
                  <div className="kindlelist-onebox cardcolorbg-content"></div>
                </div> 
                <div className="cardHeader flex items-center">
                  <div className="tooltip">
                    <span className="tooltipkindle-text bucket">
                      <p>The Book Thief by Markus Zusak</p>
                    </span>
                    <div className="Cardtitle">
                      <h3 className="social-textcontent">The Book Thief by Markus Zusak</h3>
                    </div>
                  </div>
                </div>
                {/* <div className="highlight-content">Highlights: 1</div> */}
              </div>
            </div>
          
          
            <div className="cardHolderskindle">            
              <div className="kindle-card"> 
                <div className="kindlenumber-content">4</div>
                <img className="kindleimg-content" src={Florence} alt="florence"/>
                <div className="kindlewhole-bgcontent flex">
                  <div className="kindlelist-twobox bg-green"></div>
                  <div className="kindlelist-twobox ml-[2px] bg-afc604"></div>
                  <div className="kindlelist-twobox ml-[2px] cardcolorbg-content"></div>
                  <div className="kindlelist-twobox ml-[2px] bg-0fd1bd"></div>
                </div> 
                <div className="cardHeader flex items-center">
                  <div className="tooltip">
                    <span className="tooltipkindle-text bucket">
                      <p>Florence Nightingale: A Life from beginning to end</p>
                    </span>
                    <div className="Cardtitle">
                      <h3 className="social-textcontent">Florence Nightingale: A Life from beginning to end</h3>
                    </div>
                  </div>
                </div>
                <div className="highlight-content">Highlights: 4</div>
              </div>
            </div>
           
          
            <div className="cardHolderskindle">            
              <div className="kindle-card"> 
                <div className="kindlenumber-content">4</div>
                <img className="kindleimg-content" src={Power} alt="power"/>
                <div className="kindlewhole-bgcontent flex">
                  <div className="kindlelist-box bg-green"></div>
                  <div className="kindlelist-box ml-[2px] bg-afc604"></div>
                </div> 
                <div className="cardHeader flex items-center">
                  <div className="tooltip">
                    <span className="tooltipkindle-text bucket">
                      <p>The Power of Subconscious Mind by Joseph Murphy</p>
                    </span>
                    <div className="Cardtitle">
                      <h3 className="social-textcontent">The Power of Subconscious Mind by Joseph Murphy</h3>
                    </div>
                  </div>
                </div>
                {/* <div className="highlight-content">Highlights: 2</div> */}
              </div>
            </div>
           
          
            <div className="cardHolderskindle">            
              <div className="kindle-card"> 
                <div className="kindlenumber-content">5</div>
                <img className="kindleimg-content" src={Thief} alt="thief"/>
                <div className="kindlewhole-bgcontent flex">
                  <div className="kindlelist-onebox cardcolorbg-content"></div>
                </div> 
                <div className="cardHeader flex items-center">
                  <div className="tooltip">
                    <span className="tooltipkindle-text bucket">
                      <p>The Book Thief by Markus Zusak</p>
                    </span>
                    <div className="Cardtitle">
                      <h3 className="social-textcontent">The Book Thief by Markus Zusak</h3>
                    </div>
                  </div>
                </div>
                {/* <div className="highlight-content">Highlights: 1</div> */}
              </div>
            </div>
           
        </div>
      </div>
    </div>
  );
}

export default KindleList;