import { React, Fragment, useEffect, useState } from "react";
import { Menu, Transition } from "@headlessui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import {
  faPencil,
  faTrash,
  faFolder,
  faNoteSticky,
  faChevronDown,
} from "@fortawesome/free-solid-svg-icons";
import * as fontIcons from "@fortawesome/free-solid-svg-icons";
import {
  faFolder as fasFaFolder,
  faFile as fasFaFile,
} from "@fortawesome/free-regular-svg-icons";
import shareIcon from "../assets/icons/bucketShareIcon.svg";
import pinIcon from "../assets/icons/pin-yellow.svg";
import unpinIcon from "../assets/icons/Pin.svg";
import ThreeDots from "../assets/icons/threeDots.svg";

import Profile1 from "../assets/icons/profile1.png";
import Profile2 from "../assets/icons/profile2.png";
import Profile3 from "../assets/icons/profile3.png";
import iconExport from "../components/iconExport";
import { Link } from "react-router-dom";
import "./Bucket.scss";
import Files from "../components/Files/Files.scss";
import axios from "axios";
import { toastify } from "../components/Toast";
import { ToastContainer, toast } from "react-toastify";
import { useParams } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";

import CustomizePopup from "../shades/cards/popupfinal";

import EmojiPicker, {
  EmojiClickData,
  Emoji,
  EmojiStyle,
} from "emoji-picker-react";
function SingleBucket({
  bucketId,
  handleClick,
  handleSharePop,
  handleCustomizePopup,
  handleDeleteClick,
  bucketName,
  subBucket,
  bucketDesign,
  shared,
  pined,
  bucketCard,
  bucketUpdate,
  setIsLoading,
  defaults,
  handleFilePopup,
  handleCustomPopup,
}) {
  const [isActive, setIsActive] = useState(pined);

  const [bookmarkhandlePopup, setBookmarkhandlePopup] = useState(false);
  const [filepopup, setFilePopup] = useState(false);

  const bucketNav = [
    { icon: faPencil, name: "Edit Name", href: "#" },
    // { icon: faNoteSticky, name: "Create New Card", href: "#" },
    { icon: faTrash, name: "Delete", href: "#" },
    // { icon: faFolder, name: "Customize New Card", href: "#" },
  ];

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  const handlePopup = () => {
    handleCustomPopup(bucketName, bucketId);
  };
  const handleInvitePop = () => {
    handleSharePop(bucketId);
  };
  function closeFilePopup() {
    setFilePopup(false);
  }

  const handleButtonClick = (id) => {
    const message = "Hello from child component!";
    // props.handleClick(message);
    // console.log(bucketId,"bucketId in child");
    handleClick(id);
  };

  const handleButtonDelete = (id) => {
    handleDeleteClick(id);
  };

  const updateBucket = async (bucketId, pin) => {
    setIsLoading(true);
    const userData = JSON.parse(localStorage.getItem("userData"));
    const token = localStorage.getItem("token");
    try {
      const response = await axios.patch(
        "https://prdapi.zimzee.com/api/buckets/update",
        { bucketId: bucketId, pinned: pin },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log(response);
      //  toastify("success", response.data.message);
      bucketUpdate();
      //  setIsLoading(false);
    } catch (error) {
      console.error(error);
      toastify("error", error.response.data.message);
    }
  };

  const handleStarClick = async (bucketId) => {
    setIsActive(!isActive);
    await updateBucket(bucketId, !isActive);
  };
  let filtercss = pined ? pinIcon : unpinIcon;
  // console.log(window.location.name);
  const cardId = useParams();
  // console.log(cardId.id);
  var bucketUri = cardId.id
    ? `/app/sub-buckets/${bucketId}`
    : `/app/buckets/${bucketId}`;
  console.log(defaults, "defaults");
  return (
    <div className="grid relative dropdownbucket-file">
      <div className={`card-wholecontent bucketSingle`}>
        <div className="px-4 pt-2 pb-2 sm:px-3">
          {!defaults ? (
            <div className="-ml-4 -mt-4 flex flex-wrap items-center justify-between sm:flex-nowrap">
              <div className="ml-4 pt-2">
                <div className="flex items-center">
                  {/* <FontAwesomeIcon className="transform rotate-45 text-lg text-[#5D5D5D]" icon={faThumbTack} /> */}
                  <img
                    className="dark-icon-clr"
                    src={filtercss}
                    style={{ filter: "unset" }}
                    alt="pin-icon"
                    onClick={() => handleStarClick(bucketId)}
                  />
                  {/* <img className="dark-icon-clr" src={filtercss} style={{filter:"unset"}} alt="pin-icon"  onClick={() => handleStarClick(bucketId)}/> */}
                </div>
              </div>
              <div className="ml-4 pt-2 flex flex-shrink-0">
                <button
                  type="button"
                  className="relative inline-flex items-center px-1 py-2 text-sm font-semibold text-gray-900 "
                  onClick={handleInvitePop}
                >
                  {/* <FontAwesomeIcon className="text-lg text-[#5D5D5D]" icon={faShare} /> */}
                  <img
                    className="dark-icon-clr"
                    src={shareIcon}
                    alt="shareIcon"
                  />
                </button>

                <Menu as="div" className="relative">
                  <Menu.Button className="relative ml-3 inline-flex items-center py-2 text-sm font-semibold text-gray-900 ">
                    {/* <FontAwesomeIcon className="text-lg text-[#5D5D5D]" icon={fontIcons.faEllipsisVertical} /> */}
                    <img
                      className="dark-icon-clr"
                      src={ThreeDots}
                      alt="threeDots"
                    />
                  </Menu.Button>
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items
                      style={{
                        filter: "drop-shadow(0px 0px 30px rgba(0, 0, 0, 0.2))",
                        border: "1px solid #E0E0E0",
                      }}
                      className="absolute right-0 w-52 origin-top-right rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none dropdowntrans-content"
                    >
                      <Menu.Item>
                        <a
                          href={`javascript:void(0);`}
                          className={
                            "block py-2 px-4 text-sm text-gray-700 flex"
                          }
                          onClick={() => handleButtonClick(bucketId)}
                        >
                          <p className="pr-2">
                            <FontAwesomeIcon
                              className="text-[#797979] pr-3"
                              icon={faPencil}
                            />
                          </p>
                          <p className="text-black">Edit Name</p>
                        </a>
                      </Menu.Item>
                      <Menu.Item>
                        <a
                          href={`javascript:void(0);`}
                          className={
                            "block py-2 px-4 text-sm text-gray-700 flex"
                          }
                        >
                          <p className="pr-2">
                            <FontAwesomeIcon
                              className="text-[#797979] pr-3"
                              icon={faNoteSticky}
                            />
                          </p>
                          <p className="text-black">Create New Card</p>
                        </a>
                      </Menu.Item>
                      <Menu.Item>
                        <a
                          href={`javascript:void(0);`}
                          className={
                            "block py-2 px-4 text-sm text-gray-700 flex"
                          }
                          onClick={() => handleButtonDelete(bucketId)}
                        >
                          <p className="pr-2">
                            <FontAwesomeIcon
                              className="text-[#797979] pr-3"
                              icon={faTrash}
                            />
                          </p>
                          <p className="text-black">Delete</p>
                        </a>
                      </Menu.Item>

                      <Menu.Item onClick={handlePopup}>
                        <a
                          href={`javascript:void(0);`}
                          className={
                            "block py-2 px-4 text-sm text-gray-700 flex"
                          }
                        >
                          <p className="pr-2">
                            <FontAwesomeIcon
                              className="text-[#797979] pr-3"
                              icon={faFolder}
                            />
                          </p>
                          <p className="text-black">Customize Bucket</p>
                        </a>
                      </Menu.Item>

                      {/* {bucketNav.map((item) =>  (
                                    <Menu.Item key={item.name}>
                                      {({ active }) => ( 
                                        <a
                                          href={item.href}
                                          className={classNames(
                                            active ? 'bg-gray-100' : '',
                                            'block py-2 px-4 text-sm text-gray-700 flex '
                                          )}
                                        >
                                            <p className="pr-2"><FontAwesomeIcon className="text-[#797979] pr-3" icon={item.icon} /></p>
                                          <p className="text-black">{item.name}</p>
                                        </a>
                                      )}
                                    </Menu.Item>
                                  ))} */}
                    </Menu.Items>
                  </Transition>
                </Menu>
              </div>
            </div>
          ) : (
            <div style={{ marginTop: "26px" }}></div>
          )}

          <Link to={bucketUri}>
            <div
              className="gradient-wholebackground rounded-lg cardlist-content"
              style={{
                background:
                  bucketDesign?.background?.type === "color"
                    ? bucketDesign?.background?.value
                    : `url(${bucketDesign?.background?.value})`,
              }}
            >
              {/* <div className="gradient-wholebackground rounded-lg cardlist-content" style={{background: "#ADD8E6"}}> */}
              <>
                {bucketName.length > 33 ? (
                  <div className="tooltip">
                    <span className="tooltip-text bucket">
                      <p>{bucketName}</p>
                    </span>
                    <p className={`block text-background`}>{bucketName}</p>
                  </div>
                ) : (
                  <p className={`block text-background`}>{bucketName}</p>
                )}

                {/* <p className={`${bucketTag === "" ? 'block h-7': "inline-block min-w-[110px] bg-black text-white text-[14px] px-3 rounded-bl-full rounded-br-full rounded-tr-full"}`}>{bucketTag}</p>  */}
                <div className="flex justify-center items-center">
                  {bucketDesign?.foregroundItems?.map((item) => {
                    return item.type === "icon" ? (
                      <>
                        <span
                          style={{
                            background: `${item.value?.selectedColor?.value}`,
                          }}
                        >
                          <img
                            className="emoji-icon"
                            src={
                              !defaults ? item.value.selectedIcon : item.value
                            }
                            alt="icon"
                          />
                        </span>
                        {/* <img className="emoji-icon" src={"/static/media/CB-14.cfd665ef6cf287e907f5.svg"} alt="icon" /> */}

                        {/* <div className="bg-[#24B9FF] rounded-full w-12 h-12 flex justify-center items-center">
                                          <FontAwesomeIcon icon={fontIcons[item.value]} />
                                         </div> */}
                      </>
                    ) : (
                      // <p className="text-[#838383] text-3xl">&#128540;</p>
                      <>
                        <Emoji
                          unified={item.value}
                          emojiStyle={EmojiStyle.APPLE}
                        />
                        {/* <img className="emojis-icon" src={Emoji} alt="emoji" /> */}
                      </>
                    );
                  })}
                </div>

                {/* <span className="text-2xl"></span> */}
                {/* <FontAwesomeIcon className="text-[#838383]" icon={faCamera} /> */}
              </>
            </div>
          </Link>

          <div className="innerBucketDetails grid grid-cols-3 pt-[4px]">
            <div
              className="shareCount col-span-2 h-[38px]"
              style={{ visibility: !defaults ? "visible" : "hidden" }}
            >
              <div className="profile-imgcontent">
                <img src={Profile1} alt="profile1" />
                <img src={Profile2} alt="profile2" />
                <img src={Profile3} alt="profile3" />
              </div>
              <p className="share-content dark:text-[#d3d3d3]">
                {shared?.length} Share(s)
              </p>
            </div>
            <div className="bucketsinside flex justify-end">
              <div
                className="relative px-3"
                style={{ visibility: !defaults ? "visible" : "hidden" }}
              >
                <FontAwesomeIcon
                  className="text-xl text-[#9D9D9D]"
                  icon={fasFaFolder}
                />
                <span className="card-textcontent cardtext-buttoncontent absolute right-0 bottom-0 mb-0 text-white bg-[rgba(0,0,0,0.7)] group-hover:bg-gray-200 inline-block rounded-full py-2 px-3 font-medium w-6 h-6 flex justify-center items-center">
                  {subBucket.length}
                </span>
              </div>
              <div className="relative px-3">
                <FontAwesomeIcon
                  className="text-xl text-[#9D9D9D]"
                  icon={fasFaFile}
                />
                <span className="card-textcontent cardtext-buttoncontent absolute right-0 bottom-0 mb-0 text-white bg-[rgba(0,0,0,0.7)] group-hover:bg-gray-200 inline-block rounded-full py-2 px-3 font-medium w-6 h-6 flex justify-center items-center">
                  {bucketCard?.length}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* {subBucket.length != 0  ? (<><div className="subBucket"> </div> <div className="subBucketOutline"></div></>) : null} */}
    </div>
  );
}

export default SingleBucket;
